import React from 'react'
import { Container } from 'theme-ui'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const Note = ({ content: { text } }) => (
  <>
    <Container variant='cards.paper' sx={{
      background: 'linear-gradient( 150deg, var(--theme-ui-colors-omegaLighter,#edf2f7) 80%, var(--theme-ui-colors-omegaLight,#e2e8f0) 100% )',
    }}>
        <ContentText content={text} />
    </Container>
    <Divider space='3' />
  </>
)

export default WithDefaultContent(Note)
