import React from 'react'
import { Box } from 'theme-ui'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const ImageHolder = ({ children }) => (
  <Box
    sx={{
      backgroundColor: 'red',
    }}
  >
    {children}
  </Box>
)

export default WithDefaultContent(ImageHolder)