import { default as components } from '@components/Mdx'
import { default as CTA } from './CTA'
import { default as ImageHolder } from './ImageHolder'
import { default as Expansion } from './Expansion'
import { default as QuestionAndAnswer } from './QuestionAndAnswer'
import { default as Note } from './Note'
import { default as Spacer } from './Spacer'

export default {
  ...components,
  Expansion,
  QuestionAndAnswer,
  ImageHolder,
  CTA,
  Note,
  Spacer
}
