import React from 'react'
import { Container, Flex, Box } from 'theme-ui'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const CallToAction = ({ content: { text, buttons } }) => (
  <>
    <Container variant='cards.paper' sx={{
      background: 'linear-gradient( 150deg, var(--theme-ui-colors-omegaLighter,#edf2f7) 80%, var(--theme-ui-colors-omegaLight,#e2e8f0) 100% )',
    }}>
      <Flex
        sx={{
          flexDirection: [`column`],
          justifyContent: `space-between`,
          alignItems: `center`,
          textAlign: [`center`, `left`]
        }}
      >
        <Box>
          <ContentText content={text} />
        </Box>
        <ContentButtons content={buttons} />
      </Flex>
    </Container>
    <Divider space='3' />
  </>
)

export default WithDefaultContent(CallToAction)
