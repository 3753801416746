import React from 'react'
import { Container, Box } from 'theme-ui'
import ContentContainer from '@solid-ui-components/ContentContainer'
import Divider from '@solid-ui-components/Divider'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'


const QuestionAndAnswer = ({ question, answer }) => (
  <>
    <Container variant='full' sx={{
      padding: '20px',
      background: '#ebebeb',
      borderRadius: '1.1rem',
    }}>
      <ContentContainer>
        <Box sx={{ display: `flex` }}>
          <Box sx={{ width: `20px`, flexShrink: `0` }}>
            {'Q:'}
          </Box>
          <div>
            {question}
          </div>
        </Box>
        <Box sx={{ display: `flex` }}>
          <Box sx={{ width: `20px`, flexShrink: `0` }}>
            {'A:'}
          </Box>
          <div>
            {answer}
          </div>
        </Box>
      </ContentContainer>
    </Container>
    <Divider space='3' />
  </>
)

export default WithDefaultContent(QuestionAndAnswer)
