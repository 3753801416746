import React from 'react'

import RegularPost from '../components/RegularPost'

const Post = (props) => {
  return (
    <RegularPost {...props} />
  );
}

export default Post
